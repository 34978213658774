// Here you can add other styles
.jzYGyk {
    display: flex;
    justify-content: space-between;
}

.rdt_Table {
    border: 1px solid #d5d5d5;
    border-collapse: collapse;
    margin-left: 16px;
    // margin-right: 8px;
}

.rdt_Table .rdt_TableHead {
    border-bottom: 1px solid #d5d5d5;
    font-size: 1rem;
    font-weight: bold;
    min-height: 40px !important;
    // background-color: #e5e5e5,

}

// .rdt_TableHeader {
//     margin-right: 2px;
// }

.mHisf {
    min-height: 40px !important;
    // background-color: #e5e5e5,
}

.dropdown-toggle::after {
    display: none;
}