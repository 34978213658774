/* .loading-spinner {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9;
}

.spinner {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    width: 80px;
    height: 80px;
}

.bounce1,
.bounce2,
.bounce3 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #333;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
}

.bounce1 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.bounce2 {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

@-webkit-keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0)
    }

    50% {
        -webkit-transform: scale(1)
    }
}

@keyframes sk-bounce {

    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }

    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
} */
.loading-spinner {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgb(204, 229, 255);
    z-index: 9;
}

.loader {
    position: fixed;
    left: 50%;
    top: 50%;
    border: 10px solid #f3f3f3;
    border-top: 10px solid rgba(var(--bs-primary-rgb)) !important;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}