  .dropdown-toggle::after {
    display: none !important;
  }

  .tooltip-inner {
    text-align: left !important;
    background-color: white !important;
    color: black !important;
    box-sizing: border-box !important;
    border: 1px solid lightblue !important;
    box-shadow: 5px 5px 5px 5px lightgray !important;
  }

  .tooltip.show {
    opacity: 1 !important;
  }

  .form-control {
    margin-right: 7px;
  }

  /* .fNLvxm {
    padding-left: 16px;
    padding-right: 8px;
    padding-top: 5px;
  } */